import React from "react"
import styled from "styled-components"
import { Slider } from "@igloonet-web/shared-ui"

import Ares from "../../images/old-employees/ares.jpg"
import Jedlickova from "../../images/old-employees/jedlickova.jpg"
import Jindra from "../../images/old-employees/jindra.jpg"
import Jitka from "../../images/old-employees/jitka.jpg"
import Michalg from "../../images/old-employees/michalg.jpg"
import Pesout from "../../images/old-employees/pesout.png"
import Tuha from "../../images/old-employees/tuha.png"
import Zuzana from "../../images/old-employees/zuzana.jpg"
import Onsmak from "../../images/old-employees/onsmak.png"

const ReferenceContainer = styled.div`
  background: ${(props) => props.theme.color.gray200};
`

const referenceList = [
  {
    name: "Jindrich Faborsky",
    imageSrc: Jindra,
    linkedin: "https://www.linkedin.com/in/faborsky/",
    position: "Marketingový manažer 2009 - 2013",
    text: "V igloonetu jsem začínal s online marketingem, takže jemu i jeho kolektivu dlužím opravdu hodně. Kdybych aktuálně nepodnikal a chtěl se vrátit k agenturní práci, určitě bych nepřemýšlel nad jinou firmou.",
  },
  {
    name: "Martin Pešout",
    imageSrc: Pesout,
    linkedin: "https://www.linkedin.com/in/martinpesout/",
    position: "Frontend vývojář 2007 - 2014",
    text: "V igloonetu jsem strávil úžasných 7 let. Ze všeho nejvíce musím ocenit firemní atmosféru. Kdo to nezažil, nepochopí :) Navíc firma vždy naslouchala tomu, co bych rád dělal. Bez igloo bych se nikdy nestal takovým frontend vývojařem, jakým jsem nyní. Díky!",
  },
  {
    name: "Barbora Jedličková",
    imageSrc: Jedlickova,
    linkedin: "",
    position: "Marketingová specialistka 2015",
    text: "V igloo jsem se posunula dál díky praktickým radám a přístupu kolegů z Facebook týmu. Skvělá atmosféra a úžasný kolektiv mi dodaly pocit, že chodit do práce může být zábava. igloonet nasadil laťku proklatě vysoko!",
  },
  {
    name: "Martina Tuhá",
    imageSrc: Tuha,
    linkedin: "",
    position: "Stážistka 2014",
    text: "Díky stáži v igloonetu jsem měla možnost přičichnout k základům Adwords, Skliku a SEO. Zkusila jsem si tvorbu různých formátů reklam, strategie, ale nejvíc mě strhl Facebook marketing, kterému se věnuji dodnes. V přátelském týmu skvělých lidí, kteří mi věnovali svůj čas a zkušenosti, jsem poznala kouzlo online marketingu. Dík!",
  },
  {
    name: "Jitka Kolaříková",
    imageSrc: Jitka,
    linkedin: "",
    position: "Frontend vývojářka 2016",
    text: "Díky stáži v igloo jsem se za měsíc naučila vytvořit super webovou stránku, poznala spoustu smartass tučňáků, několikrát se přejedla pizzou a měla kofeinový šok. Díky za tuto příležitost, nezapomenutelná!",
  },
  {
    name: "Michal Gritzbach",
    imageSrc: Michalg,
    linkedin: "https://www.linkedin.com/in/michalgritzbach/",
    position: "Backend vývojář 2014 - 2016",
    text: "Strávil jsem v igloonetu přes dva roky a už jsem potřeboval změnu. Přestože občas bylo i dusno, zvlášť když jsme se v roce 2015 prakticky zdvojnásobili, tučňáci mi budou ještě dlouho chybět. Atmosféra a kafe jsou tu prostě jedinečné.",
  },
  {
    name: "Marek Hulán",
    imageSrc: Ares,
    linkedin: "https://www.linkedin.com/in/marek-hul%C3%A1n-0307097/",
    position: "Ruby on Rails vývojář 2009 - 2012",
    text: "igloonet není typický start-up, ale rozhodně nastartuje vaši kariéru. Doporučuji každému, kdo má hlad po zkušenostech.",
  },
  {
    name: "Zuzana Šumlanská",
    imageSrc: Zuzana,
    linkedin:
      "https://www.linkedin.com/in/zuzana-%C5%A1umlansk%C3%A1-197353a1/",
    position: "Frontend vývojářka 2016",
    text: "Poznala jsem spoustu skvělých lidí, se kterými se chci stýkat dál. Z profesního hlediska jsem si potvrdila, že frontend chci rozhodně dělat. Když se ohlédnu, vidím za sebou kus práce a progres, o jakém se mi ani nesnilo.",
  },
  {
    name: "David Czernín",
    imageSrc: Onsmak,
    linkedin: "",
    position: "Frontend vývojář",
    text: "Necelé dva roky v Igloo jsou a budou pro mne srdcovka. Poznal jsem v něm spoustu skvělých tučňáků, vypil snad desítky litrů kávy a získal mnoho pracovních zkušeností. Avšak igloo tvoří hlavně atmosféra a lidé, za kterými jsem se rád vracel.",
  },
]

const Reference: React.FC = () => (
  <ReferenceContainer id="employes">
    <Slider
      title="Tučňákem jednou, tučňákem napořád"
      data={referenceList}
      style={{ minHeight: "50rem" }}
    />
  </ReferenceContainer>
)

export default Reference
