import React from "react"
import { graphql } from "gatsby"
import { Gallery, ContactFormJobs } from "@igloonet-web/shared-ui"
import Reference from "../components/jobs/reference"
import {
  Layout,
  JobPositionList,
  Benefits,
  BannerJobs,
  JobsContact,
} from "../components"

import OgImage from "../images/position/og-image-jobs.png"

import Shelve from "../images/position/ad-shelve.png"

import { Helmet } from "react-helmet"

export default function JobsPage({ data }) {
  const positionList = [
    {
      title: "Ruby vývojář(ka) pro interní systémy",
      text: "Pojď nám prosím spravit Redmine.",
      image: Shelve,
      href: "/ruby-vyvojar-ka",
    },
  ]

  const instaPhoto = data.allInstagramContent.edges.map((edge) => ({
    imageSrc: edge.node.localImage.childImageSharp.original.src,
    text: edge.node.caption,
    time: edge.node.timestamp,
  }))

  return (
    <Layout>
      <Helmet>
        <title>
          Každý strávíme ⅓ života v práci. Vybírej chytře, s kým ji prožiješ |
          igloonet
        </title>
        <meta
          name="description"
          content="Chceš pracovat v kolektivu, kde bude tvé slovo slyšet?
          Staň se tučňákem a pracuj v igloo. #nejsmezledu"
        />
        <meta property="og:title" content="Pracuj v igloo!" />
        <meta property="og:image" content={OgImage} />
      </Helmet>
      <BannerJobs />

      <JobPositionList data={positionList}></JobPositionList>

      <JobsContact />

      <Reference />

      <Benefits />

      <Gallery data={instaPhoto} />

      <div id="formular">
        <ContactFormJobs heading="Stačí ještě jeden krok a už se nás nezbavíš" />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query InstagramQuery {
    allInstagramContent(limit: 14) {
      edges {
        node {
          caption
          timestamp(formatString: "")
          localImage {
            childImageSharp {
              original {
                src
              }
            }
          }
        }
      }
    }
  }
`
